import { CircularProgress, ThemeProvider } from '@mui/material'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

const Home = lazy(() => import('../pages/Home'))

const OneTimeMessageWithProvider = React.lazy(() =>
    import('../pages').then((module) => ({
        default: module.OneTimeMessageWithProvider
    }))
)

export enum ROUTES {
    HOME = '/',
    ONETIME = '/chat/onetime'
}

const Router: React.FC = () => {
    const theme = useTheme()
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Suspense fallback={<CircularProgress />}>
                    <Switch>
                        <ProtectedRoute
                            path={ROUTES.ONETIME}
                            component={OneTimeMessageWithProvider}
                        />
                        <ProtectedRoute path={ROUTES.HOME} component={Home} />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
